import React, { Component } from 'react';

// Others
import { clone } from 'lodash';
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import VendorService from 'services/modules/VendorService';
import CompanyService from 'services/modules/CompanyService';
import CompanySettingMap from 'services/mapData/CompanySettingMap';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import CustomerMap from 'services/mapData/CustomerMap';

import StringUtils from 'lib/StringUtils';

const CompanySettingsContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.companyService = new CompanyService();
        this.vendorService = new VendorService();

        this.state = {
            openDialog: false,
            openThemeDialog: false,
            openConfirmationDialog: false,
            isEditingVendor: false,
            selectedVendorId: null,
            record: {
                companyCode: 0,
                companyDbName: '',
                companyName: '',
                companyAddress: '',
                companyCity: '',
                companyState: '',
                companyZip: '',
                companyCountry: '',
                companyPhone: '',
                companyFax: '',
                companyWebsite: '',
                companyContact: '',
                companyEin: '',
                companyEmail: '',
                carfaxId: '',
                secureCloseDmsguid: '',
                secureCloseEnabled: false,
                companyTimeZoneId: '',
                companyTimezone: 0,
                companyLogo: '',
            },
            zipData: [],
            listCity: [],
            isDecoderEnabled: false,
            isDecodingZip: false,
            isLoading: false,
            isCarfaxEnabled: false,
        };

        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
    }

    componentWillUnmount() {
    }

    onEnableDecoder() {
        const { state: { isDecoderEnabled } } = this;
        if (!isDecoderEnabled) {
            this.setState({ isDecoderEnabled: true });
        }
    }

    onChangeValue(field, value) {
        this.setState(({ record }) => {
            const newRecord = { ...record };
            newRecord[field] = value;
            return { record: newRecord };
        }, () => {
            if (field === 'companyZip' && value.length === 5) {
                const { state: { isDecoderEnabled } } = this;
                if (isDecoderEnabled) {
                    this.decodeZip(value);
                }
            }
        });
    }

    onSave() {
        const { record } = this.state;
        const company = CompanySettingMap.mapCompanySettingToUpdate(record);

        this.companyService.updateCompany({ company })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updateCompany) {
                    ModalUtils.successMessage(null, 'Company updated successfully');
                    this.getServicesData();
                }
            });
    }

    onUploadFile(file) {
        this.companyService.addCompanyLogo({ file })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.addCompanyLogo) {
                    ModalUtils.successMessage(null, 'Company logo updated successfully');
                    this.getServicesData();
                }
            });
    }

    getServicesData() {
        this.setState({ isLoading: true });
        this.companyService.getCompany()
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.company) {
                    const { company } = data;
                    this.setState({ record: company });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });

        this.companyService.getCompanyCarfax()
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    const { isCarfaxEnabled } = data;
                    this.setState({ isCarfaxEnabled });
                }
            });
    }

    toogleThemeDialog = () => this.setState(({ openThemeDialog }) => ({ openThemeDialog: !openThemeDialog }));

    toogleCompanyCarfax = (value) => {
        this.setState({ isCarfaxEnabled: value });
        this.companyService.updateCompanyCarfax({ input: value })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    ModalUtils.successMessage(null, 'Company updated successfully');
                    this.getServicesData();
                }
            });
    }

    removeCompanyLogo() {
        this.companyService.removeCompanyLogo()
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.removeCompanyLogo) {
                    ModalUtils.successMessage(null, 'Company logo removed successfully');
                    this.getServicesData();
                }
            });
    }

    decodeZip(zip = '', ignoreCity = false) {
        const input = {
            zip,
        };

        this.setState({ isDecodingZip: true });
        this.graphqlClient
            .query(CustomerQuery.DECODE_ZIP_CODE, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.decodeZip) {
                    const { decodeZip } = data;

                    /* In order to keep the city value, we need
                    to assign it using the record from database */
                    if (ignoreCity && decodeZip.length > 0) {
                        const { state: { record } } = this;
                        const alreadyExist = decodeZip.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(record.companyCity));

                        if (!alreadyExist) {
                            const newData = clone(decodeZip[0]);
                            newData.city = record.insuranceCity;
                            decodeZip.unshift(newData);
                        }
                    }

                    const listCity = decodeZip.map((item) => CustomerMap.mapCity(item));
                    if (listCity.length > 1) listCity.unshift({ label: 'None', value: '' });
                    const zipData = decodeZip;

                    this.setState(({ record, isDecoderEnabled }) => {
                        if (isDecoderEnabled) {
                            const newRecord = {
                                ...record,
                                companyCity: '',
                                companyState: '',
                            };
                            return {
                                listCity, zipData, record: newRecord, isDecodingZip: false,
                            };
                        }
                        return { listCity, zipData, isDecodingZip: false };
                    });

                    if (decodeZip.length === 1) {
                        const currentData = decodeZip[0];

                        this.setState(({ record }) => {
                            const newRecord = {
                                ...record,
                                companyCity: currentData.city,
                                companyState: currentData.state,
                            };

                            return { record: newRecord };
                        });
                    }
                }
            });
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.onEnableDecoder = this.onEnableDecoder.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onUploadFile = this.onUploadFile.bind(this);
        this.removeCompanyLogo = this.removeCompanyLogo.bind(this);
        this.toogleCompanyCarfax = this.toogleCompanyCarfax.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onEnableDecoder={this.onEnableDecoder}
                onChangeValue={this.onChangeValue}
                onUploadFile={this.onUploadFile}
                removeCompanyLogo={this.removeCompanyLogo}
                onSave={this.onSave}
                toogleThemeDialog={this.toogleThemeDialog}
                toogleCompanyCarfax={this.toogleCompanyCarfax}
            />
        );
    }
};

export default CompanySettingsContainer;
