import { gql } from '@apollo/client';

export default class CreditMutation {
    static GENERATE_CREDIT_SCORE = gql`    
        mutation generateCreditScore(
            $lotId: Int,
            $type: String!,
            $recordId: Int!,
            $uniqueCode: String!,
            $recordType: String!,
            $multipleBureaus: String!
        ) {
            generateCreditScore(
                type: $type,
                lotId: $lotId
                recordId: $recordId,
                uniqueCode: $uniqueCode,
                recordType: $recordType,
                multipleBureaus: $multipleBureaus,
            ) {
                url
                vendor
            }
        }
    `;
}
