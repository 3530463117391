const CatalogEnum = Object.freeze({
    CASHIER_DEAL_REASON: 'Cashier Deal Reason',
    CASHIER_SERVICE_REASON: 'Cashier Service Reason',
    CASHIER_OTHER_REASON: 'Cashier Other Reason',
    CASHIER_PETTY_CASH_REASON: 'Cashier Petty Cash Reason',
    DEAL_TYPES: 'Lead Deal Type',
    SERVICE_CUSTOMER_TYPE: 'Service Customer Type',
    SHIPPING_METHOD: 'Shipping Method',
    SHIPPING_TERM: 'Shipping Term',
    CASHIER_PARTS_REASON: 'Parts Ticket Reason',
    JOB_TYPE: 'Job Type',
    FORM_PROVIDER: 'Form Provider',
    PROVIDER_FORM_CATEGORY: 'Provider Form Category',
    CATEGORY: 'Category',
    SUB_CATEGORY: 'SubCategory',
});

export default CatalogEnum;
