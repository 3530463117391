import React, { useReducer, useEffect } from 'react';
import {
    makeStyles, Button, Chip, useTheme,
    useMediaQuery, Grid, Tooltip,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import { isEmpty } from 'lodash';
import Split from 'react-split';
import ModalUtils from 'utils/ModalUtils';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Container from 'components/widgets/Container';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import { Form, Col } from 'react-bootstrap';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import {
    BankingProcessTypes, AccountingCBStatus, AccountingSubModules, GeneralAction, ProcessType, AccountingCOAType, AccountingCBStatusList,
} from 'utils/enum/AccountingEnum';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import NumberUtils from 'lib/NumberUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import {
    LocalAtmIcon, SaveAltIcon, EditOutlinedIcon, BlockOutlinedIcon,
    LowPriorityIcon, ThumbUpAltOutlinedIcon, PrintOutlinedIcon,
} from 'components/icons';
import JournalDetail from 'components/modules/accounting/banking/journalDetail/JournalDetail';
import DropdownQuery from 'components/widgets/DropdownQuery';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router';
import AccountingCBMapper from 'services/mapData/AccountingCBMapper';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import AccountingMutation from 'services/graphQL/mutate/accounting/AccountingMutation';
import InputSearch from 'components/widgets/InputSearch';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import printJS from 'print-js';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import PropTypes from 'prop-types';
import AccountPayableQuery from 'services/graphQL/query/accounting/AccountPayableQuery';
import AccountingSubscription from 'services/graphQL/subscription/accounting/accountingSubscription';
import TypeFilter from 'components/widgets/accounting/TypeFilter';
import AccountingLockDate from 'components/widgets/accounting/AccountingLockDate';
import If from 'components/widgets/conditional/If';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));
const ownStyle = makeStyles((theme) => ({
    highlightText: {
        color: theme.palette.text.red,
    },
    paddingBottom10: {
        paddingBottom: '10px',
    },
}));

const ACTION_TYPES = {
    SET_EDITING: 'setEditing',
    SET_NEW: 'setNew',
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_ROW: 'setSelectedRow',
    CLOSE_SCREEN: 'setCloseScreen',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValues',
    SET_CB_ACTION: 'setCBAction',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_NEW: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
            openCheck: action.value === BankingProcessTypes.CHECK,
            openDeposit: action.value === BankingProcessTypes.DEPOSIT,
        };
    }
    case ACTION_TYPES.SET_EDITING: {
        return {
            ...state,
            isEditing: true,
            openCheck: action.value.screenType === BankingProcessTypes.CHECK,
            openDeposit: action.value.screenType === BankingProcessTypes.DEPOSIT,
        };
    }
    case ACTION_TYPES.SET_SELECTED_ROW: {
        const { type } = action.value;
        let process = '';
        if (type.toLowerCase().indexOf(BankingProcessTypes.CHECK.toLowerCase()) >= 0) process = BankingProcessTypes.CHECK.toLowerCase();
        else if (type.toLowerCase().indexOf(BankingProcessTypes.DEPOSIT.toLowerCase()) >= 0) process = BankingProcessTypes.DEPOSIT.toLowerCase();

        return {
            ...state,
            selectedRow: action.value,
            processType: process,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
            dataFromSubscription: action.dataFromSubscription,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.CLOSE_SCREEN: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
            openCheck: false,
            openDeposit: false,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_CB_ACTION: {
        const { actionRecord, actionType } = action.value;

        const currentRecord = state.table.records.find((item) => item.cbId === actionRecord?.cbId);

        if (currentRecord.status.toLowerCase() === AccountingCBStatus.PRINTED.toLowerCase()
        && actionType === GeneralAction.PRINT) {
            return {
                ...state,
                ...action.value,
                showReprintCheck: true,
            };
        }

        return {
            ...state,
            ...action.value,
        };
    }
    default: return action.value;
    }
};

const BankingList = (props) => {
    const keyStore = new KeyStore();
    const ACCOUNTING_BANKING_READ = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_READ);
    const ACCOUNTING_BANKING_CHECK_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_CHECK_WRITE);
    const ACCOUNTING_BANKING_DEPOSIT_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_DEPOSIT_WRITE);
    const ACCOUNTING_BANKING_TRANSFER_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_TRANSFER_WRITE);
    const ACCOUNTING_BANKING_CHECK_APPROVE = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_CHECK_APPROVE);
    const ACCOUNTING_BANKING_CHECK_PRINT = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_CHECK_PRINT);
    const ACCOUNTING_BANKING_CHECK_VOID = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_CHECK_VOID);
    const INVOICE_READ = keyStore.hasPermission(Permission.INVOICE_READ);

    const { comeFromInvoice, invoiceSelectedId } = props;
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 50,
            },
            useDateRange: false,
            search: '',
            fromDate: '',
            toDate: new Date(),
            status: '',
            bankAccount: -1,
            processType: '',
            type: '',
            userName: '',
        },
        table: {
            records: [],
            totalCount: 0,
        },
        selectedRow: null,
        isEditing: false,
        openCheck: false,
        openDeposit: false,
        approvingActive: false,
        approvingAll: false,
        excludeFromApprovingAll: [],
        listToApproval: [],
        dataFromSubscription: null,
        actionRecord: null,
        actionType: '',
        showReprintCheck: false,
        showReprintCheckNewNumber: false,
        newReprintCheckNumber: 0,
        showLockedDate: false,
        loadingLastCheck: false,
        dateToPost: new Date(),
        lockedDate: null,
        showForceBecauseIsSold: false,
        alreadyForceVoidDate: null,
        soldStocks: '',
    };

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params, table, selectedRow, processType,
        dataFromSubscription, actionRecord, actionType,
        showReprintCheck, showLockedDate, showReprintCheckNewNumber,
        newReprintCheckNumber, loadingLastCheck, lockedDate, alreadyForceVoidDate,
        showForceBecauseIsSold,
    } = state;

    const [getDataFromInvoice, { loading: loadingFromInvoice, refetch }] = useLazyQuery(AccountPayableQuery.GET_CB_BY_INVOICE_LIST, {
        onCompleted: (res) => {
            const result = res?.getAccountingCBMatchProcessList?.data;
            if (result) {
                const processedData = AccountingCBMapper.mapCBLines(result);
                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records: processedData,
                        totalCount: processedData.length,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (comeFromInvoice) {
            dispatch({ type: ACTION_TYPES.SET_TABLE, records: [], totalCount: 0 });

            if (invoiceSelectedId > 0) {
                getDataFromInvoice({
                    variables: {
                        processRecordId: Number(invoiceSelectedId),
                        processTypeId: Number(ProcessType.ACCOUNTS_PAYABLE),
                        sort: null,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comeFromInvoice, invoiceSelectedId]);

    const {
        data, loading, error, subscribeToMore,
    } = useQuery(AccountingQuery.GET_ACCOUNTINGCB_LIST, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
                useDateRange: params.useDateRange,
                fromDate: params.fromDate,
                toDate: params.toDate,
                status: params.status,
                bankAccount: params.bankAccount,
                type: params.type,
                userName: params.userName,
            },
            sort: [
                { fieldName: 'date', dir: DataSort.DESC },
                { fieldName: 'writtenDate', dir: DataSort.DESC },
            ],
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onFilterChange = (param, value) => {
        if ((param === 'fromDate' || param === 'toDate') && DateUtils.getOnlyDate(params[param]) === DateUtils.getOnlyDate(value)) return;
        if (params[param] === value) return;

        let { fromDate } = params;
        if (param === 'toDate' && isEmpty(params.fromDate.toString())) fromDate = new Date(DateUtils.subtractAndFormatUTC(new Date(value), 5, 'days'));
        if (param === 'useDateRange' && value) {
            fromDate = new Date(DateUtils.subtractAndFormatUTC(new Date(params.toDate), 5, 'days'));
        }

        if (param === 'fromDate') fromDate = value;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                fromDate,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    useEffect(() => {
        if (!comeFromInvoice) {
            if (error) {
                ModalUtils.errorMessage(error?.graphQLErrors);
                return;
            }

            if (!loading) {
                const { records } = table;
                const { getAccountingCBList } = data;

                const processedData = AccountingCBMapper.mapCBLines(getAccountingCBList.data);

                records.push(...processedData);

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records,
                        totalCount: getAccountingCBList.totalCount,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    useEffect(() => {
        const unsubscribe = subscribeToMore({
            document: AccountingSubscription.CHECK_OR_DEPOSIT_CHANGED,
            updateQuery: (prev, { subscriptionData }) => {
                dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { dataFromSubscription: { ...subscriptionData.data.checkOrDepositChanged } } });
            },
        });

        return () => unsubscribe();
    }, [subscribeToMore]);

    const applySubscription = (processedData) => {
        // search should be evaluated first
        if (params.search !== '') {
            const search = new RegExp(`${params.search}.*`, 'i');
            if (search.test(processedData[0].paidTo)
                   || search.test(processedData[0].memo)
                   || search.test(processedData[0].checkNumber)
                   || search.test(processedData[0].achCheckNumber)
                   || search.test(processedData[0].depositNumber)
                   || search.test(processedData[0].amount)) {
                return true;
            }
        }

        if ((params.useDateRange
            && (new Date(processedData[0].date) >= new Date(params.fromDate)
            || new Date(processedData[0].date) <= new Date(params.toDate))
        )
               || (params.status !== '' && processedData[0].status === params.status)
               || (params.bankAccount > 0 && processedData[0].bankAccount === params.bankAccount)
               || (params.type !== '' && processedData[0].type === params.type)
               || (params.userName !== '' && processedData[0].sysUser === params.userName)
               || (!params.useDateRange && new Date(processedData[0].date) <= new Date(params.toDate))) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (dataFromSubscription?.id > 0) {
            if (comeFromInvoice) {
                if (refetch) refetch();
                return;
            }
            const { action, data: subsData, id } = dataFromSubscription;

            if (action === SubscriptionActionType.ADDED) {
                const { records = [], totalCount = 0 } = table;
                const currentData = JSON.parse(subsData);

                const processedData = AccountingCBMapper.mapCBLines(currentData);
                if (applySubscription(processedData)) {
                    records.unshift(...processedData);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records,
                            totalCount: totalCount + 1,
                        },
                        dataFromSubscription: null,
                    });
                }
            } else if (action === SubscriptionActionType.UPDATED) {
                const currentRecords = table?.records ?? [];
                const currentData = JSON.parse(subsData);

                const processedData = AccountingCBMapper.mapCBLines(currentData);

                const currentIndex = currentRecords.findIndex((item) => Number(item.cbId) === Number(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);
                    currentRecords.splice(currentIndex, 0, ...processedData);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: currentRecords,
                        },
                        dataFromSubscription: null,
                    });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFromSubscription]);

    const onCloseActionDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                actionRecord: null,
                actionType: '',
                showReprintCheck: false,
                showLockedDate: false,
                showReprintCheckNewNumber: false,
                newReprintCheckNumber: null,
                lockedDate: null,
                alreadyForceVoidDate: null,
                showForceBecauseIsSold: false,
            },
        });
    };

    const [voidCheck, { loading: voiding }] = useMutation(AccountingMutation.VOID_CBID, {
        onCompleted: (mutationData) => {
            const { success, isLockedDate, lockedDate: currentLockDate } = mutationData?.voidCheckOrDeposit;

            if (success) {
                ModalUtils.successMessage(null, 'Successfully void!');

                onCloseActionDialog();
            }

            if (isLockedDate) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        showLockedDate: isLockedDate,
                        lockedDate: currentLockDate,
                        actionType: '',
                        showReprintCheck: false,
                    },
                });
                return;
            }

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    actionRecord: null,
                    actionType: '',
                    showReprintCheck: false,
                    showLockedDate: false,
                    lockedDate: null,
                    alreadyForceVoidDate: null,
                },
            });
        },
        onError: (errorMessage) => {
            const errorIncludeSold = String(errorMessage).includes('sold vehicles');
            if (errorIncludeSold) {
                const soldStocks = String(errorMessage).replace(/[^0-9,]+/g, '');
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        showForceBecauseIsSold: true,
                        soldStocks,
                    },
                });
            } else ModalUtils.errorMessage([errorMessage]);

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    actionRecord: errorIncludeSold ? actionRecord : 0,
                    actionType: '',
                    showLockedDate: false,
                    lockedDate: null,
                    alreadyForceVoidDate: errorIncludeSold ? alreadyForceVoidDate : null,
                },
            });
        },
    });

    const [printCheck, { loading: printing }] = useMutation(AccountingMutation.PRINT_CHECK, {
        onCompleted: (mutationData) => {
            if (mutationData?.printCheck) {
                onCloseActionDialog();
                printJS({
                    printable: mutationData?.printCheck,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [printRemittance, { loading: printingRemittance }] = useMutation(AccountingMutation.PRINT_REMITTANCE, {
        onCompleted: (mutationData) => {
            if (mutationData?.printRemittance) {
                onCloseActionDialog();
                printJS({
                    printable: mutationData?.printRemittance,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [getLastCheck] = useLazyQuery(AccountingQuery.GET_LAST_CHECK, {
        onCompleted: (res) => {
            if (res.getLastCheck) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        showReprintCheckNewNumber: true,
                        showReprintCheck: false,
                        loadingLastCheck: false,
                        newReprintCheckNumber: res.getLastCheck.result,
                    },
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getLastACHCheck] = useLazyQuery(AccountingQuery.GET_LAST_ACHCHECK, {
        onCompleted: (res) => {
            if (res.getLastACHCheck) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        showReprintCheckNewNumber: true,
                        showReprintCheck: false,
                        loadingLastCheck: false,
                        newReprintCheckNumber: res.getLastACHCheck.result,
                    },
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onActionConfirm = async () => {
        if (actionType === GeneralAction.VOID) {
            await voidCheck({
                variables: {
                    cbId: actionRecord?.cbId,
                    forceVoid: false,
                },
            });
        } else if (actionType === GeneralAction.PRINT) {
            await printCheck({
                variables: {
                    cbIds: [actionRecord?.cbId],
                },
            });
        }
    };

    const onActionClick = (val, action) => {
        if (action === GeneralAction.PRINT_REMITTANCE) {
            const currentDate = new Date();
            printRemittance({
                variables: {
                    cbId: val.cbId,
                    printDate: DateUtils.format(`${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`, DateFormat.DATETIME_WITHOUT_SECONDS),
                },
            });

            return;
        }

        dispatch({
            type: ACTION_TYPES.SET_CB_ACTION,
            value: {
                actionRecord: val,
                actionType: action,
            },
        });
    };

    const reprintCheck = async (clearedCBID = null) => {
        const currentCBID = clearedCBID || actionRecord?.cbId;
        await printCheck({
            variables: {
                cbIds: [currentCBID],
                reprint: true,
                newCheckNumber: null,
            },
        });
    };

    const reprintWithNewCheckNumber = async () => {
        const newCheckNumber = Number(document.getElementById('print-newCheckNumber').value);
        if (!newCheckNumber) {
            ModalUtils.errorMessage([{ message: 'Please enter a check Number' }]);
            return;
        }

        await printCheck({
            variables: {
                cbIds: [actionRecord?.cbId],
                reprint: true,
                newCheckNumber,
            },
        });
    };

    const forceToVoid = async (values) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                actionType: '',
                showReprintCheck: false,
                alreadyForceVoidDate: values.voidDate,
            },
        });

        voidCheck({
            variables: {
                cbId: actionRecord?.cbId,
                forceVoid: true,
                dateToPost: values.voidDate,
                forceFromSold: false,
            },
        });
    };

    const onActionConfirmVoidSold = async () => {
        await voidCheck({
            variables: {
                cbId: actionRecord?.cbId,
                forceVoid: alreadyForceVoidDate !== null,
                dateToPost: alreadyForceVoidDate,
                forceFromSold: true,
            },
        });
    };

    const getFormattedText = (status, value) => {
        const textColorCls = status.toLowerCase() === AccountingCBStatus.VOID.toLowerCase() ? classes.voidCls : '';
        return <span className={textColorCls}>{value}</span>;
    };

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Bank Account',
            minWidth: 200,
            width: 200,
            id: 'BankAccount',
            accessor: 'bankAccount',
            show: !comeFromInvoice,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => {
                const { original, original: { status } } = cell;
                const { accountNumber = '', description = '' } = original?.accountCOA;
                return (
                    getFormattedText(status, `${accountNumber} - ${description}`)
                );
            },
        },
        {
            Header: comeFromInvoice ? 'Check' : 'Check / Deposit',
            minWidth: 120,
            width: 120,
            id: 'ProcessNumber',
            accessor: 'processNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Paid To',
            minWidth: 60,
            width: 150,
            id: 'PaidTo',
            accessor: 'paidTo',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Amount',
            minWidth: 90,
            width: 90,
            id: 'Amount',
            accessor: 'amount',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Date',
            minWidth: 90,
            width: 90,
            id: 'Date',
            accessor: 'date',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value, original: { status } }) => getFormattedText(status, DateUtils.getOnlyDate(value)),
        },
        {
            Header: 'Memo',
            minWidth: 90,
            id: 'memo',
            accessor: 'memo',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Type',
            minWidth: 90,
            width: 120,
            id: 'type',
            accessor: 'type',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Status',
            minWidth: 90,
            width: 90,
            id: 'Status',
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                let badgeStatus = classes.primary;
                if (status.toLowerCase() === AccountingCBStatus.CLEARED.toLowerCase()) badgeStatus = classes.badgeCleared;
                else if (status.toLowerCase() === AccountingCBStatus.APPROVED.toLowerCase()) badgeStatus = classes.badgeApproved;
                else if (status.toLowerCase() === AccountingCBStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;
                else if (status.toLowerCase() === AccountingCBStatus.PRINTED.toLowerCase()) badgeStatus = classes.badgePrinted;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
        {
            Header: 'User',
            minWidth: 90,
            width: 90,
            id: 'SysUser',
            accessor: 'sysUser',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Cleared',
            minWidth: 90,
            width: 90,
            id: 'ClearedDate',
            accessor: 'clearedDate',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value, original: { status } }) => getFormattedText(status, DateUtils.getOnlyDate(value)),
        },
        {
            Header: 'Actions',
            width: isMobile ? 120 : 'auto',
            id: 'actions',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter, isMobile ? '' : classes.hidden),
            className: clsx(classes.columnStyle, classes.columnCenter, isMobile ? '' : classes.hidden, isMobile ? '' : 'actionColumnTarget'),
            Cell: (cellData) => {
                const {
                    original: {
                        type, cbId, status, processTypeId,
                    },
                    original,
                } = cellData;

                const isPayroll = type.toLowerCase().indexOf(BankingProcessTypes.PAYROLL.toLowerCase()) >= 0;
                const isBill = type.toLowerCase().indexOf(BankingProcessTypes.BILL.toLowerCase()) >= 0;
                const readCheck = type.toLowerCase().indexOf(BankingProcessTypes.CHECK.toLowerCase()) >= 0;
                const readDeposit = type.toLowerCase().indexOf(BankingProcessTypes.DEPOSIT.toLowerCase()) >= 0;
                const readTransfer = processTypeId === ProcessType.TRANSFER_BANK_ACCOUNT || type.toLowerCase().indexOf('transfer') >= 0;

                let screen = '';
                if (processTypeId === ProcessType.TRANSFER_BANK_ACCOUNT || type.toLowerCase().indexOf('transfer') >= 0) {
                    screen = BankingProcessTypes.TRANSFER.toLowerCase();
                } else if (type.toLowerCase().indexOf(BankingProcessTypes.DEPOSIT.toLowerCase()) >= 0) screen = BankingProcessTypes.DEPOSIT.toLowerCase();
                else if (type.toLowerCase().indexOf(BankingProcessTypes.CHECK.toLowerCase()) >= 0) screen = BankingProcessTypes.CHECK.toLowerCase();
                else ModalUtils.infoMessage([{ message: 'Form not available' }]);

                return (
                    <div className={classes.buttonSpacing}>
                        {(readCheck || readDeposit || readTransfer)
                            && (
                                <Button
                                    onClick={() => history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/${screen}/${cbId}`)}
                                    variant="outlined"
                                    startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                                    size="small"
                                >
                                    { isMobile ? '' : 'Edit' }
                                </Button>
                            )}
                        { !isPayroll
                        && ACCOUNTING_BANKING_CHECK_VOID
                        && status.toLowerCase() !== AccountingCBStatus.VOID.toLowerCase()
                        && status.toLowerCase() !== AccountingCBStatus.CLEARED.toLowerCase()
                            && (
                                <Button
                                    onClick={() => onActionClick(original, GeneralAction.VOID)}
                                    variant="outlined"
                                    startIcon={<BlockOutlinedIcon className={classes.actionButtonError} />}
                                    size="small"
                                >
                                    { isMobile ? '' : 'Void' }
                                </Button>
                            )}
                        {readCheck
                        && ACCOUNTING_BANKING_CHECK_PRINT
                        && (status.toLowerCase() === AccountingCBStatus.APPROVED.toLowerCase()
                        || status.toLowerCase() === AccountingCBStatus.PRINTED.toLowerCase())
                        && (
                            <Button
                                onClick={() => onActionClick(original, GeneralAction.PRINT)}
                                variant="outlined"
                                startIcon={<PrintOutlinedIcon className={classes.actionButtonPrint} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Print' }
                            </Button>
                        )}
                        {readCheck
                        && ACCOUNTING_BANKING_CHECK_PRINT
                        && (status.toLowerCase() === AccountingCBStatus.CLEARED.toLowerCase())
                        && (
                            <Button
                                onClick={() => reprintCheck(original.cbId)}
                                variant="outlined"
                                startIcon={<PrintOutlinedIcon className={classes.actionButtonPrint} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Print Copy' }
                            </Button>
                        )}
                        {readCheck
                        && INVOICE_READ
                        && isBill
                        && (
                            <Button
                                onClick={() => onActionClick(original, GeneralAction.PRINT_REMITTANCE)}
                                variant="outlined"
                                startIcon={<PrintOutlinedIcon className={classes.actionButtonPrint} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Remittance' }
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    const currentTable = (
        <Table
            data={table?.records}
            columns={columns}
            cursor="default"
            load={loading || loadingFromInvoice}
            totalRecords={table?.totalCount}
            loadMore={loadMore}
            rowSelected
            enableRowHover
            className="-highlight actionsInLine"
            getTrProps={(_, rowInfo) => ({
                onClick: () => {
                    if (selectedRow?.cbId === rowInfo.original.cbId) return;
                    dispatch({ type: ACTION_TYPES.SET_SELECTED_ROW, value: rowInfo.original });
                },
            })}
        />
    );

    return (
        <>
            { comeFromInvoice && currentTable }
            { !comeFromInvoice && (
                <div className={classes.main}>
                    <Header>
                        <div className={classes.containerFilterSearch}>
                            <InputSearch
                                customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                                initialSearch={params.search || ''}
                                onSearch={onSearch}
                            />
                            <Form.Group controlId="formBasicCheckbox" className={classes.alignContent}>
                                <Form.Check
                                    checked={params.useDateRange}
                                    type="checkbox"
                                    label="Use date range"
                                    className={classes.labelFont}
                                    onChange={(e) => onFilterChange('useDateRange', e.target.checked)}
                                />
                            </Form.Group>
                            {params.useDateRange
                    && (
                        <DatePicker
                            popperContainer={CalendarContainer}
                            className={clsx('form-control form-control-sm', classes.date, isMobile ? classes.dateInRange : '')}
                            selected={params.fromDate}
                            maxDate={params.toDate}
                            onChange={(value) => onFilterChange('fromDate', value)}
                            placeholderText="mm/dd/yyyy"
                        />
                    )}
                            <DatePicker
                                popperContainer={CalendarContainer}
                                className={clsx('form-control form-control-sm', classes.date, isMobile ? classes.dateInRange : '')}
                                selected={params.toDate || new Date()}
                                minDate={params.useDateRange ? params.fromDate : null}
                                onChange={(value) => onFilterChange('toDate', value)}
                                placeholderText="mm/dd/yyyy"
                            />
                            {ACCOUNTING_BANKING_READ && (
                                <DropdownQuery
                                    name="bankAccount"
                                    value={params.bankAccount}
                                    placeholder="Account Number"
                                    className={clsx('form-control-xs', classes.dropdownWidth200, isMobile ? classes.searchFull : '')}
                                    onChange={(name, newValue) => onFilterChange('bankAccount', newValue)}
                                    dataSource={{
                                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                        variables: {
                                            paginate: {
                                                init: 0,
                                                ignoreLimit: true,
                                            },
                                            filters: {
                                                accountTypes: [AccountingCOAType.BANK, AccountingCOAType.CASH],
                                            },
                                        },
                                        rootData: 'getAccountingCOAList.data',
                                        idField: 'accountNumber',
                                        descriptionField: 'fullDescription',
                                    }}
                                    defaultEmptyLineText="All"
                                />
                            )}
                            <DropdownQuery
                                name="status"
                                value={params.status}
                                placeholder="Status"
                                className={clsx('form-control-xs', classes.dropdown, isMobile ? classes.searchFull : '')}
                                onChange={(name, newValue) => onFilterChange('status', newValue === 0 ? '' : newValue)}
                                dataSource={{
                                    localData: AccountingCBStatusList,
                                    idField: 'value',
                                    descriptionField: 'label',
                                }}
                                defaultEmptyLineText="All"
                            />
                            <DropdownQuery
                                name="userName"
                                value={params.userName}
                                className={clsx('form-control-xs', classes.dropdownWidth150, isMobile ? classes.searchFull : '')}
                                placeholder="User"
                                onChange={(name, newValue) => onFilterChange(name, newValue === 0 ? '' : newValue)}
                                dataSource={{
                                    query: AccountingQuery.GET_ACCOUNTINGCB_USER_LIST,
                                    rootData: 'getAccountingCBUsers',
                                    idField: 'userName',
                                    descriptionField: 'userName',
                                }}
                                defaultEmptyLineText="All"
                            />
                            <TypeFilter name="type" value={params.type} onChange={onFilterChange} />
                        </div>
                        <div className={clsx(classes.buttonSpacing, classes.justifyContentCenter)}>
                            {ACCOUNTING_BANKING_CHECK_WRITE
                        && (
                            <Tooltip title="New Check" placement="top-start">
                                <span>
                                    <Button
                                        onClick={() => history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/check`)}
                                        variant="outlined"
                                        startIcon={<LocalAtmIcon />}
                                        disabled={loading}
                                        size="small"
                                    >
                                        New Check
                                    </Button>
                                </span>
                            </Tooltip>
                        )}

                            {ACCOUNTING_BANKING_DEPOSIT_WRITE && (
                                <Tooltip title="New Deposit" placement="top-start">
                                    <span>
                                        <Button
                                            onClick={() => history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/deposit`)}
                                            variant="outlined"
                                            startIcon={<SaveAltIcon />}
                                            disabled={loading}
                                            size="small"
                                        >
                                            New Deposit
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}

                            { ACCOUNTING_BANKING_TRANSFER_WRITE && (
                                <Tooltip title="New Transfer between bank accounts" placement="top-start">
                                    <span>
                                        <Button
                                            onClick={() => history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/transfer`)}
                                            variant="outlined"
                                            startIcon={<LowPriorityIcon />}
                                            disabled={loading}
                                            size="small"
                                        >
                                            New Transfer
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                            {ACCOUNTING_BANKING_CHECK_APPROVE
                    && (
                        <Tooltip title="Approve Checks" placement="top-start">
                            <span>
                                <Button
                                    onClick={() => history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/approve-checks`)}
                                    variant="outlined"
                                    startIcon={<ThumbUpAltOutlinedIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    Approve checks
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                            {ACCOUNTING_BANKING_CHECK_PRINT
                    && (
                        <Tooltip title="Prints checks" placement="top-start">
                            <span>
                                <Button
                                    onClick={() => history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/print-checks`)}
                                    variant="outlined"
                                    startIcon={<PrintOutlinedIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    Print checks
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                        </div>
                    </Header>
                    <Container className={classes.containerSplit}>
                        <Split
                            sizes={[55, 45]}
                            className={classes.split}
                            direction="vertical"
                            minSize={0}
                        >
                            {currentTable}
                            <JournalDetail cbId={selectedRow?.cbId} processType={processType} />
                        </Split>
                    </Container>
                </div>
            ) }
            <ConfirmDialog
                title={`${actionType} Record #${actionRecord?.processNumber}`}
                description={`Are you sure you want to ${actionType} this record?`}
                open={actionRecord?.cbId > 0 && !(printing || voiding || printingRemittance)
                    && !showReprintCheckNewNumber && !showReprintCheck && !showLockedDate}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseActionDialog}
                onClickSecondary={onCloseActionDialog}
                onClickPrimary={onActionConfirm}
            />
            <ConfirmDialog
                title="Printing check"
                description="This check has been printed. Do you want to reprint with a new check number?"
                open={actionRecord?.cbId > 0 && !(printing || voiding) && showReprintCheck && !showReprintCheckNewNumber && !showLockedDate}
                variant="outlined"
                titlePrimary="Yes"
                disablePrimaryButton={loadingLastCheck}
                titleSecondary="No, print a copy"
                onClose={onCloseActionDialog}
                onClickSecondary={() => reprintCheck()}
                onClickPrimary={() => {
                    dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        value: {
                            loadingLastCheck: true,
                        },
                    });

                    if (actionRecord.achCheckNumber) {
                        getLastACHCheck({
                            variables: {
                                bankAccount: Number(actionRecord.accountCOA.accountNumber),
                            },
                        });
                    } else {
                        getLastCheck({
                            variables: {
                                bankAccount: Number(actionRecord.accountCOA.accountNumber),
                            },
                        });
                    }
                }}
            />
            <ConfirmDialog
                title="Printing check"
                description="Please enter a new check number"
                open={actionRecord?.cbId > 0 && !printing && showReprintCheckNewNumber}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseActionDialog}
                onClickSecondary={onCloseActionDialog}
                onClickPrimary={reprintWithNewCheckNumber}
            >
                <Grid item xs={12}>
                    <Form.Group as={Col} className={classes.voidPaymentInput}>
                        <Form.Control
                            id="print-newCheckNumber"
                            type="text"
                            size="sm"
                            defaultValue={newReprintCheckNumber}
                        />
                    </Form.Group>
                </Grid>
            </ConfirmDialog>
            {showLockedDate && lockedDate && !(printing || voiding)
            && (
                <AccountingLockDate
                    recordNumber={String(actionRecord.processNumber || actionRecord.cbId)}
                    onCloseActionDialog={onCloseActionDialog}
                    onForceToVoid={(values) => forceToVoid(values)}
                    lockedDate={new Date(lockedDate)}
                    showComment={false}
                />
            )}
            {showForceBecauseIsSold && (
                <ConfirmDialog
                    title="The system cannot void this record"
                    open={showForceBecauseIsSold && !(printing || voiding) && !showLockedDate}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseActionDialog}
                    onClickSecondary={onCloseActionDialog}
                    onClickPrimary={onActionConfirmVoidSold}
                >
                    <div>
                        <p className={classes.paddingBottom10}>
                            Some of the stock numbers in this transaction are sold and voiding this record will not reverse them.
                        </p>
                        <p>{state.soldStocks.length > 0 ? ` (Stocks: ${state.soldStocks})` : ''}</p>
                        <br />
                        <p className={clsx(classes.paddingBottom10, classes.highlightText)}>Reversing them in financials would require a General Journal.</p>
                        <p className={classes.paddingBottom10}>Are you sure you want to void this record?</p>
                    </div>
                </ConfirmDialog>
            )}
            <If condition={printing || printingRemittance}>
                <DialogActionMessage message="Printing... " />
            </If>
            <If condition={voiding}>
                <DialogActionMessage message="Voiding record... " />
            </If>
        </>
    );
};

BankingList.propTypes = {
    comeFromInvoice: PropTypes.bool,
    invoiceSelectedId: PropTypes.number,
};

BankingList.defaultProps = {
    comeFromInvoice: false,
    invoiceSelectedId: 0,
};

export default BankingList;
