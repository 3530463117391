const DealStructureFields = {
    TITLE_REGISTRATION: 'TITLE REGISTRATION',
    FEES: 'FEES',
    AMOUNT_FINANCED: 'AMOUNT FINANCED',
    DEFERRED_DOWN_PAYMENT: 'DEFERRED DOWN PAYMENT',
    SALES_TAX: 'SALES TAX',
    ROLL_PAYMENT: 'ROLL PAYMENT',
    ROLL_BALANCE: 'ROLL BALANCE',
    LEASE_MILEAGE: 'LEASE MILEAGE',
    LEASE_PAYMENT: 'LEASE PAYMENT',
};

const CustomerType = {
    BUYER: 'BUYER',
    CO_BUYER: 'CO BUYER',
    PROSPECT: 'PROSPECT',
    CO_PROSPECT: 'CO PROSPECT',
};

const ImportCustomer = Object.freeze({
    CRM: 'CRM',
    NEO: 'Neo',
    MAGILOOP: 'MagiLoop',
});

export const ProductType = {
    VSC: 'VSC',
    GAP: 'GAP',
    PRODUCT: 'PRODUCT',
    WARRANTY: 'Warranty',
};

export const DealFields = {
    CONTRACT: 'CONTRACT',
    PORTFOLIO: 'PORTFOLIO',
};

const DealSection = {
    DEAL_STRUCTURE: 'DEAL STRUCTURE',
    BACK_END: 'BACK END',
    DEAL_RECAP: 'DEAL RECAP',
    BUYER_INFORMATION: 'BUYER INFORMATION',
    BUYER_ADDRESS: 'BUYER ADDRESS',
    BUYER_EMPLOYMENT: 'BUYER EMPLOYMENT',
    INSURANCE: 'INSURANCE',
    TRADE_IN: 'TRADE IN',
    SOLD_VEHICLE: 'SOLD VEHICLE',
    CO_BUYER_INFORMATION: 'CO BUYER INFORMATION',
};

const Actions = {
    FLAG_DEAL: 'Flag Deal',
    UNFLAG_DEAL: 'Unflag Deal',
    POST_TO: 'Post to',
    DEACTIVATE_DEAL: 'Deactivate Deal',
    REACTIVATE_DEAL: 'Reactivate Deal',
    TRANSFER_TO_RFC: 'Transfer to RFC',
    TRANSFER_TO_LOT: 'Transfer to Lot',
    SEND_TO_TAG_AGENCY: 'Send to Tag Agency',
    SEND_TO_SECURE_CLOSE: 'Send to Secure Close',
    FUNDING_STATUS: 'Funding Status',
};

const FlagDeal = {
    FLAG: 'FLAG',
    UNFLAG: 'UNFLAG',
};

const DealActions = {
    MOVE_TO: 'MOVE TO',
    TOGGLE_FLAG: 'TOGGLE FLAG',
    TOGGLE_ACTIVATE_DEAL: 'DEACTIVATE DEAL',
    TRANSFER_TO_LOT: 'TRANSFER TO LOT',
    POST_TO_ACCOUNTING: 'POST TO ACCOUNTING',
};

const FundingStatus = {
    NOT_FUNDED: 'Not Funded',
    SENT_OUT: 'Sent Out',
    RETURNED: 'Returned',
    BOOKED: 'Booked',
    FUNDED: 'Funded',
};

const FundingStatusValues = {
    NOT_FUNDED: 'NOT_FUNDED',
    SENT_OUT: 'SENT_OUT',
    RETURNED: 'RETURNED',
    BOOKED: 'BOOKED',
    FUNDED: 'FUNDED',
};

const DealStatus = {
    ARCHIVED: 'Archived',
    DELETED: 'Deleted',
    FNI: 'F&I',
    QUOTE: 'Quote',
    ACCOUNTING: 'Accounting',
};

const DealTabsTitle = Object.freeze({
    DEAL: 'DEAL',
    BUYER: 'BUYER',
    VEHICLE: 'VEHICLE',
    FORMS: 'FORMS',
});

const DealType = Object.freeze({
    CASH: 'CASH',
    WHOLESALE: 'WHOLESALE',
    FINANCE: 'FINANCE',
    DRAFT: 'DRAFT',
    BHPH: 'BHPH',
    OUTSIDE_FINANCE: 'OUTSIDE FINANCE',
    SAME_AS_CASH: 'SAME AS CASH',
    LHPH: 'LHPH',
    LEASE: 'LEASE',
});

const RelationType = {
    AUNT: 'Aunt',
    BROTHER: 'Brother',
    DAUGHTER: 'Daughter',
    FATHER: 'Father',
    FIANCE: 'Fiance',
    FRIEND: 'Friend',
    GRANDFATHER: 'Grandfather',
    GRANDMOTHER: 'Grandmother',
    MOTHER: 'Mother',
    SISTER: 'Sister',
    SON: 'Son',
    SPOUSE: 'Spouse',
    UNCLE: 'Uncle',
    OTHER: 'Other',
};

const FormsActions = Object.freeze({
    RENAME: 'Rename',
    DUPLICATE: 'Duplicate',
    DELETE: 'Delete',
    Add: 'Add',
});

const TagType = Object.freeze({
    NEW: 'New',
    TRANSFER: 'Transfer',
});

const PostedStatus = Object.freeze({
    ALL: 'All',
    NOT_POSTED: 'Not Posted',
    POSTED: 'Posted',
});

const TagAgencyStatus = Object.freeze({
    ALL: 'All',
    NOT_SENT: 'Not Sent',
    SENT: 'Sent',
});

const TransferStatus = Object.freeze({
    SENT: 'Sent',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
});

const TransferActions = Object.freeze({
    ACCEPT: 'Accept',
    REJECT: 'Reject',
});

const RollBalanceOptions = Object.freeze({
    SALES_PRICE: 'SALESPRICE',
    DOWN_PAYMENT: 'DOWNPAYMENT',
});

const FinanceDefaultCalculation = Object.freeze({
    TERM: 'Term',
    PAYMENTAMOUNT: 'Payment Amount',
});

const PaymentFrequency = Object.freeze({
    WEEKLY: 'WEEKLY',
    BI_WEEKLY: 'BI-WEEKLY',
    BIWEEKLY: 'BIWEEKLY',
    SEMIMONTHLY: 'SEMIMONTHLY',
    SEMI_MONTHLY: 'SEMI-MONTHLY',
    MONTHLY: 'MONTHLY',
});

export {
    DealStructureFields, CustomerType, DealSection, DealActions, DealTabsTitle, ImportCustomer,
    Actions, FlagDeal, FundingStatus, FundingStatusValues, DealStatus, DealType, RelationType,
    FormsActions, TagType, PostedStatus, TagAgencyStatus, TransferStatus, TransferActions, RollBalanceOptions, FinanceDefaultCalculation,
    PaymentFrequency,
};
