import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { modules } from 'utils/enum/modules';
import { makeStyles } from '@material-ui/core/styles';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import Container from 'components/widgets/Container';
import GpsSettings from 'components/modules/settings/integrations/GpsSettings';
import Underwriting from 'components/modules/settings/underwriting/Underwriting';

// icons
import AppsIcon from '@material-ui/icons/Apps';
import GpsFixedOutlinedIcon from '@material-ui/icons/GpsFixedOutlined';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const subTabs = ['gps', 'underwriting'];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];

    const INTEGRATIONS_SETTINGS_READ = keyStore.hasPermission(Permission.INTEGRATIONS_SETTINGS_READ);
    const INTEGRATIONS_SETTINGS_WRITE = keyStore.hasPermission(Permission.INTEGRATIONS_SETTINGS_WRITE);

    if (INTEGRATIONS_SETTINGS_READ) {
        settings.push(
            {
                index: 0,
                text: 'GPS',
                icon: <GpsFixedOutlinedIcon />,
                component: <GpsSettings canWrite={INTEGRATIONS_SETTINGS_WRITE} />,
            },
        );

        settings.push(
            {
                index: 1,
                icon: <AppsIcon />,
                text: 'Underwriting',
                component: <Underwriting />,
            },
        );
    }

    return settings.filter((setting) => setting !== null);
};

const IntegrationsSettings = () => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();
    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/${modules.SETTINGS}/${maintab}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const settingsItems = getSettings();
    return (
        <Container className={classes.rootContainer}>
            <List component="nav" className={classes.sideBar}>
                {settingsItems.map((item) => (
                    <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            <div className={classes.content}>
                {settingsItems.map((item) => (
                    activeIndex === item.index && <div key={item.index}>{item.component}</div>
                ))}
            </div>
        </Container>
    );
};

export default IntegrationsSettings;
